<template>
  <div class="" v-if="invoice">
    <TopBarTitleComponent title="Información Factura" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-center justify-content-md-end align-items-center">
          <el-dropdown @command="handleCommand" class="dropdown-theme-gray-outline d-flex align-items-center mr-4">
            <span class="el-dropdown-link">
              <span class="ml-2">Opciones</span>
              <i class="el-icon-arrow-down el-icon--right mr-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template v-if="invoice.status === 'draft' || invoice.status === 'failed'">
                <el-dropdown-item command="invoice-edit"><img :src="iconEdit" alt="" /> Editar</el-dropdown-item>
              </template>
              <template v-if="invoice.status === 'success'">
                <el-dropdown-item command="invoice-nuled"><img :src="iconNuled" alt="" /> Anular
                  factura</el-dropdown-item>
              </template>

              <el-dropdown-item v-if="invoice.status !== 'success' && invoice.status !== 'nuled'
                " command="invoice-delete">
                <img :src="iconDelete" alt="" /> Eliminar
              </el-dropdown-item>

              <el-dropdown-item command="invoice-send">
                <img :src="iconSend" alt="" /> Enviar
                documento</el-dropdown-item>

              <el-dropdown-item command="invoice-history-email">
                <img :src="iconHistory" alt="" /> Historial
                envio</el-dropdown-item>

              <el-dropdown-item command="invoice-download"><img :src="iconDownload" alt="" /> Descargar
                PDF</el-dropdown-item>
              <el-dropdown-item command="invoice-paid"><img :src="iconCheck" alt="" /> {{ textOptionIsPaid }} como
                pagada</el-dropdown-item>
              <el-dropdown-item command="invoice-print"><img :src="iconDownload" alt="" /> Vista de
                impresión</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <ButtonPrimaryComponent v-if="invoice.status !== 'success' && invoice.status !== 'nuled'" title="Firmar"
            @onSaveClick="toogleModalInvoiceSRI" />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4 pt-4">
      <b-row v-if="invoice.sri_sign_message">
        <b-col>
          <AlertInfoComponent title="Notificación SRI" type="error" class="mb-4"
            :description="invoice.sri_sign_message" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="card box-shadow card-quotes-view py-4">
            <div class="card-body">
              <div class="card-quotes-view-header">
                <b-row>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-logo mb-4">
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="4">
                          <div class="logo">
                            <business-logo />
                          </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="7" lg="8">
                          <div class="info">
                            <h4 class="h4 font-bold m-0">
                              N° {{ invoice.number_invoice }}
                            </h4>
                            <h2 class="h2 font-bold m-0"></h2>
                            <p class="font-semi-bold mt-2">
                              {{ getUser.user_business.social_reason }}
                            </p>
                            <p class="font-semi-bold">
                              RUC: {{ getUser.user_business.business_ruc }}
                            </p>
                            <p class="font-semi-bold">
                              {{ getUser.user_business.business_address }}
                            </p>
                          </div>
                          <SignAuthorizationInfo class="mt-4" :accessKey="invoice.access_key"
                            :signMode="invoice.sign_mode" />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-info">
                      <!-- <div
                        class="header-title-section bg-color-primary py-0 mb-4 border-radius"
                      >
                        <h2 class="h2 font-semi-bold text-center py-2">
                          Cotización
                        </h2>
                      </div> -->
                      <b-row>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Fecha de emisión</label>
                          <p class="m-0">
                            {{ invoice.emission_date_for_view }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Punto de emisión</label>
                          <p class="m-0" v-if="invoice.user_business_e_point_id">
                            {{ invoice.emission_point.code }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block mb-2">Status</label>
                          <StatusReadDocumentsComponent :status.sync="invoice.status" />
                        </b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Cliente</label>
                          <p class="m-0">{{ invoice.client.name }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Documento</label>
                          <p class="m-0">{{ invoice.client.document }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Correo</label>
                          <p class="m-0">
                            {{ invoice.client.email }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="card-quotes-view-body mt-5">
                <div class="card-quotes-view-body-products">
                  <div class="card-quotes-view-table table-items">
                    <b-row class="table-items-header">
                      <b-col xs="12" sm="12" md="1">
                        <h6 class="h6 font-bold mb-0 ml-2">Código</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="5">
                        <h6 class="h6 font-bold mb-0">Detalle</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Cantidad</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Precio U.</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Subtotal</h6>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row class="mt-4 d-flex" v-for="(product, index) in invoice.products" :key="index">
                    <b-col xs="12" sm="12" md="1">
                      <p class="m-0 ml-2">{{ product.product.code }}</p>
                    </b-col>
                    <b-col xs="12" sm="12" md="5">
                      <p class="m-0">
                        <strong>{{ product.product.name }}</strong> <br />
                        <br />
                        <span v-html="product.product_detail" class="text-pre-wrap"></span>
                      </p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">{{ product.product_quantity }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">${{ product.product_amount }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">
                        ${{
                          handleCalculateSubTotal(
                            product.product_quantity,
                            product.product_amount
                          )
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <h5 class="h5 font-semi-bold d-block mb-2">
                      Forma de pago
                    </h5>
                    {{ invoice.sri_forms_payment.title }}

                    <h5 class="h5 font-semi-bold d-block mt-4">
                      Información adicional
                    </h5>
                    <b-row v-for="(additional, index) in invoice.additional_extras" :key="index">
                      <b-col>{{
                        `${additional.name} - ${additional.description}`
                      }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" offset-md="2">
                    <DocumentCalculator readOnly :setProducts="invoice.products" :setDisc="invoice.discount"
                      :setServ="invoice.service" />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <el-dialog :visible.sync="invoicePrint">
      <div class="d-flex justify-content-center">
        <print-invoice :invoice="invoice" id="printInvoice" />
      </div>
      <div class="d-flex justify-content-center align-items-center mt-5">
        <button type="button" class="btn md__btn-primary mr-4" @click="print">
          Imprimir
        </button>
      </div>
    </el-dialog>

    <el-dialog width="35%" title="" :visible.sync="invoiceSRIOuterVisible" class="">
      <div class="wrapper__invoice-signature">
        <h4 class="h4 text-center font-semi-bold text-break text-color-primary">
          ¿Desea firmar y enviar el documento al SRI?
        </h4>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <button type="button" class="btn md__btn-third-secondary mr-4" @click="toogleModalInvoiceSRI">
            Cancelar
          </button>
          <ButtonPrimaryComponent title="Confirmar" @onSaveClick="sendInvoiceSRI" />
        </div>
      </div>
    </el-dialog>

    <el-dialog width="35%" title="" :visible.sync="invoiceNuledOuterVisible" class="">
      <div class="wrapper__invoice-nuled text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea anular la factura?
        </h4>

        <p class="text-color-primary">
          Para completar todo el proceso de anulación la factura debe ser
          anulada en el
          <LinkSRI />
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button @click="toggleModalInvoiceNuled" class="btn md__btn-secondary mr-4">
            Cancelar
          </button>
          <ButtonPrimaryComponent title="Anular" @onSaveClick="onInvoiceNuled" />
        </div>
      </div>
    </el-dialog>

    <el-dialog width="35%" title="" :visible.sync="invoiceDeleteOuterVisible" class="">
      <div class="wrapper__invoice-delete text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea eliminar la factura?
        </h4>

        <p class="text-color-primary text-break">
          Recuerde que solo puede eliminar las facturas en borrador.
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button @click="toggleModalInvoiceDelete" class="btn md__btn-secondary mr-4">
            Cancelar
          </button>
          <ButtonPrimaryComponent title="Eliminar" @onSaveClick="onInvoiceDelete" />
        </div>
      </div>
    </el-dialog>

    <WrapperSendDocumentDrawer @onDocumentSend="onDocumentSend" @onCloseDrawer="onCloseDocumentSendDrawer"
      :visibleDocumentSend="visibleDocumentSend" :toMails="toMails" :isLoadingConfigTexts="false" />

    <DocumentSendEmailHistory @onCloseDrawer="onCloseDocumentSendEmailHistory"
      :visibleDocumentHistoryEmail="visibleDocumentHistoryEmail" :emails="invoice.traking_history_emails" />
  </div>
</template>

<script>
import BusinessLogo from "@/components/Business/BusinessLogo";
import AlertInfoComponent from "@/components/Alerts/Info";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import invoiceService from "../services/invoiceService";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import SignAuthorizationInfo from "../../../components/SignAuthorizationInfo";
import WrapperSendDocumentDrawer from "../../../../components/WrapperSendDocumentDrawer";
import DocumentCalculator from "../../../components/DocumentCalculator";
import DocumentSendEmailHistory from "../../../components/DocumentSendEmailHistory";
import PrintInvoice from "../components/PrintInvoice.vue";
import LinkSRI from "@/components/General/LinkSRI";

import { parseFloatFormat } from "@/assets/utils/format";
import {
  icoNotifyDanger,
  iconEdit,
  iconDelete,
  iconNuled,
  iconSend,
  iconHistory,
  iconDownload,
  iconCheck
} from "@/services/resources";
import { downloadFile } from "@/assets/utils/files";

export default {
  name: "InvoiceViewPage",
  data: () => ({
    icoNotifyDanger,
    iconEdit,
    iconDelete,
    iconNuled,
    iconSend,
    iconHistory,
    iconDownload,
    iconCheck,
    invoiceSRIOuterVisible: false,
    invoiceNuledOuterVisible: false,
    invoiceDeleteOuterVisible: false,
    visibleDocumentSend: false,
    visibleDocumentHistoryEmail: false,
    invoice: null,
    invoicePrint: false
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    toMails() {
      const { client } = this.invoice;
      if (client && client.email) return [client.email];
      return [];
    },
    textOptionIsPaid() {
      const { is_paid } = this.invoice;
      return is_paid ? "Desmarcar" : "Marcar";
    }
  },
  methods: {
    print() {
      const prtHtml = document.getElementById("printInvoice").innerHTML;

      /*  let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style')
      ]) {
        stylesHtml += node.outerHTML;
      }
      console.log(stylesHtml) */
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
                  body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";}
                  .d-flex { display: -ms-flexbox !important; display: -webkit-box !important; display: flex !important;}
                  .justify-content-center { -ms-flex-pack: center !important;  -webkit-box-pack: center !important; justify-content: center !important;}
                  .justify-content-between {  -ms-flex-pack: justify !important; -webkit-box-pack: justify !important; justify-content: space-between !important;}
                  .mt-4, .my-4 { margin-top: 1.5rem !important; }
                  .m-0 { margin: 0 !important; }
                  .m-2 { margin: 0.5rem !important; }
                  .mt-2, .my-2 { margin-top: 0.5rem !important; }
                  .font-semi-bold { font-weight: 600; }
                  h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.5rem; }
                  .font-bold { font-weight: 700; }
                  p { margin: 0; }
                  .text-pre-wrap { white-space: pre-wrap; word-wrap: break-word; }
                  .access_key { font-size: 12px !important; }
            </style>
          </head>
          <body>
            <div id="dataPrint">
              ${prtHtml}
            </div>
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    handleCalculateSubTotal(quantity, price) {
      return parseFloatFormat(quantity * price);
    },
    goPage(name) {
      this.$router.push({ name });
    },
    toogleModalInvoiceSRI() {
      this.invoiceSRIOuterVisible = !this.invoiceSRIOuterVisible;
    },
    async sendInvoiceSRI() {
      this.$store.dispatch("toggleRequestLoading", {
        text:
          "Por favor espere un momento, su documento esta siendo enviado al SRI."
      });
      try {
        this.toogleModalInvoiceSRI();
        let response = await invoiceService
          .sendInvoiceSRI({
            user_business_invoice_id: this.invoice.id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        } else {
          this.$notifications.error({
            message
          });
        }
        this.invoice = data;
      } catch (error) {
        return false;
      }
    },
    async getInvoice(invoiceId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await invoiceService
          .getInvoceId(invoiceId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response) {
          this.invoice = response.data.data;
        }
      } catch (error) {
        return false;
      }
    },
    async onInvoiceNuled() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: invoiceId } = this.invoice;
        let response = await invoiceService
          .updateInvoiceStatus({
            user_business_invoice_id: invoiceId
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalInvoiceNuled();
          });
        const { success, data, message } = response.data;
        if (success) {
          this.invoice = data;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async onInvoiceDelete() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: invoiceId } = this.invoice;
        let response = await invoiceService
          .deleteInvoceId(invoiceId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalInvoiceDelete();
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.goPage("sales.documents");
        }
      } catch (error) {
        return false;
      }
    },
    handleCommand(command) {
      switch (command) {
        case "invoice-nuled":
          this.toggleModalInvoiceNuled();
          break;
        case "invoice-edit":
          this.goPage("sales.invoices.edit", {
            id: this.invoice.id
          });
          break;
        case "invoice-send":
          this.visibleDocumentSend = true;
          break;
        case "invoice-history-email":
          this.visibleDocumentHistoryEmail = true;
          break;
        case "invoice-delete":
          this.toggleModalInvoiceDelete();
          break;
        case "invoice-download":
          this.invoiceDownload();
          break;
        case "invoice-paid":
          this.invoicePaid();
          break;
        case "invoice-print":
          this.invoicePrint = true;
          break;
      }
    },
    async invoicePaid() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { is_paid, id: invoiceId } = this.invoice;
        let response = await invoiceService
          .invoicePaid({
            user_business_invoice_id: invoiceId,
            is_paid: !is_paid
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.invoice = data;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async invoiceDownload() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { number_invoice, id } = this.invoice;
        let response = await invoiceService
          .invoiceDownload({
            id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { data } = response;
        if (data) {
          downloadFile(data, `Factura - #${number_invoice}`);
        }
      } catch (error) {
        return false;
      }
    },
    async sendInvoiceEmail({ data }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await invoiceService
          .sendInvoiceEmail({
            data
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.visibleDocumentSend = false;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    toggleModalInvoiceNuled() {
      this.invoiceNuledOuterVisible = !this.invoiceNuledOuterVisible;
    },
    toggleModalInvoiceDelete() {
      this.invoiceDeleteOuterVisible = !this.invoiceDeleteOuterVisible;
    },
    onCloseDocumentSendDrawer() {
      this.visibleDocumentSend = false;
    },
    onCloseDocumentSendEmailHistory() {
      this.visibleDocumentHistoryEmail = false;
    },
    onDocumentSend({ data }) {
      data.append("invoice_id", this.invoice.id);

      this.sendInvoiceEmail({ data });
    }
  },
  components: {
    TopBarTitleComponent,
    AlertInfoComponent,
    ButtonPrimaryComponent,
    StatusReadDocumentsComponent,
    SignAuthorizationInfo,
    WrapperSendDocumentDrawer,
    DocumentCalculator,
    DocumentSendEmailHistory,
    BusinessLogo,
    PrintInvoice,
    LinkSRI
  },
  mounted() {
    const { id: invoiceId } = this.$route.params;
    if (invoiceId) {
      this.getInvoice(invoiceId);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-quotes-view {
  .card-quotes-view-header {
    .card-quotes-view-logo {
      p {
        margin: 0;
      }
    }
  }

  .card-quotes-view-body-products {
    min-height: 300px;
  }

  /* @media print {
      #printInvoice2 {
        max-width: 12cm;
        overflow: hidden;
        background: #999;
      }
  }  */
}
</style>
